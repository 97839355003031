<template>
  <div>
    <h1>当前版本v1.1.9</h1>
    <h2>
      历史更新记录
    </h2>
    <div>
      <div>
        <h3>
          v1.1.9
        </h3>
        <p>2022年8月9日</p>
        <p>gitlab-runner自动发布</p>
      </div>
      <div>
        <h3>
          v1.1.8
        </h3>
        <p>2022年8月2日</p>
        <p>修复社区监控模块帖子链接不正确问题</p>
      </div>
      <div>
        <h3>
          v1.1.6
        </h3>
        <p>2022年7月29日</p>
        <p>新增产品说明</p>
      </div>
      <div>
        <h3>
          v1.1.5
        </h3>
        <p>2022年7月25日</p>
        <p>1 社区展示id</p>
        <p>2 帖子任务默认补齐t3_</p>
      </div>
      <div>
        <h3>
          v1.1.4
        </h3>
        <p>2022年7月21日</p>
        <p>流加载改进</p>
      </div>
      <div>
        <h3>
          v1.1.3
        </h3>
        <p>2022年7月15日</p>
        <p>1. 方案最后采集时间提醒</p>
        <p>2. 一键添加方案</p>
        <p>3. 内容展示优化</p>
      </div>
      <div>
        <h3>
          v1.1.2
        </h3>
        <p>2022年7月5日</p>
        <p>1. 退出问题</p>
        <p>2. 样式优化</p>
      </div>
      <div>
        <h3>
          v1.1.1
        </h3>
        <p>2022年4月27日</p>
        <p>1. 帖子高亮修复</p>
        <p>2. 方案输入框提示语修复</p>
      </div>
      <div>
        <h3>
          v1.1.0
        </h3>
        <p>2022年4月26日</p>
        <p>全新ui</p>
      </div>
      <div>
        <h3>
          v1.0.0
        </h3>
        <p>2022年1月28日</p>
        <p>初始版本上线</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
